import { LocalImagesContext } from '@matix/utils';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Seo } from '../components/seo/seo';

const ProductsByCategoryList = (props) => {
   const productImages = props.data.pageData.edges.flatMap((e) => e.node.productImages);
   const localImagesDesktop = props.data.localImagesDesktop.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: false };
   });
   const localImagesMobile = props.data.localImagesMobile.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: true };
   });

   props.data.seoData = props.data.seoMetaData.edges[0]?.node?.frontmatter;
   props.data.pageData.uri = props.uri;

   return (
      <LocalImagesContext.Provider
         value={{ images: [...productImages, ...localImagesDesktop, ...localImagesMobile], basePath: props.pageContext.localImageBasePath }}
      >
         <Seo {...props} />
         <MDXRenderer {...props.data}>{props.data.template.body}</MDXRenderer>
      </LocalImagesContext.Provider>
   );
};

export default ProductsByCategoryList;

export const pageQueryTwo = graphql`
   query templateProductsByCategoryList($template: String, $locale: String, $limit: Int, $skip: Int, $varOneExtra: String, $localImages: [String]) {
      template: mdx(frontmatter: { template: { eq: $template }, locale: { eq: $locale } }) {
         id
         body
      }

      seoMetaData: allMdx(filter: { frontmatter: { type: { eq: "category-seo" }, locale: { eq: $locale }, category: { eq: $varOneExtra } } }) {
         edges {
            node {
               ...SeoFragment
            }
         }
      }

      pageData: allMdx(
         sort: { fields: frontmatter___id, order: ASC }
         limit: $limit
         skip: $skip
         filter: { frontmatter: { type: { eq: "product" }, locale: { eq: $locale }, deleted: { eq: false }, category: { eq: $varOneExtra } } }
      ) {
         edges {
            node {
               ...ProductFragment
            }
         }
         pageInfo {
            currentPage
            hasPreviousPage
            hasNextPage
            pageCount
         }
      }

      localImagesDesktop: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 930, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      localImagesMobile: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 360, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      grouping: allMdx(filter: { frontmatter: { type: { eq: "product" }, locale: { eq: $locale } } }) {
         categories: group(field: frontmatter___category) {
            fieldValue
            totalCount
         }
         providers: group(field: frontmatter___provider) {
            fieldValue
            totalCount
         }
      }
   }
`;
